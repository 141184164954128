import React, { FC, useCallback } from 'react';
import { BazaarRatingSummary } from '@phx-husky/bazaarvoice-components';

import Image from 'common/Image';
import { gtmService } from 'utils/gtmService';

import { ShoppingOptionsCardProps } from './models';

import './ShoppingOptionsCard.scss';

const ShoppingOptionsCard: FC<ShoppingOptionsCardProps> = ({
  image,
  variants,
  description,
  variantsDescription,
  buyNowTitle,
  buyNowOption,
  buyDescription,
  onFocus,
  sku,
  productName,
  productCategory,
  emitGtmEvent,
  ean,
  showBazaarvoiceStarRating,
}) => {
  const bvProductId = sku || ean;
  const retailerClickHandler = useCallback(
    (retailerName) => () => {
      gtmService.emitRetailerClick(
        {
          productName,
          productCategory,
          sku,
          variants,
        },
        retailerName
      );
    },
    []
  );

  return (
    <div className="shopping-options-card" data-testid="shopping-options-card">
      <Image imageData={image} alt="" objectFit="contain" />
      <h3 className="shopping-options-card__variant">{variants}</h3>
      {variantsDescription ? (
        <p className="shopping-options-card__variant-description">{variantsDescription}</p>
      ) : null}
      <p className="shopping-options-card__description">{description}</p>
      <div className="shopping-options-card__buy-info">
        <h4 className="shopping-options-card__buy-now-title">{buyNowTitle}</h4>
        {buyDescription ? (
          <p className="shopping-options-card__buy-description">{buyDescription}</p>
        ) : null}
        <ul className="shopping-options-card__buy-now-options">
          {buyNowOption.map(({ image: buyNowImage, link, alt: buyNowAlt }) => (
            <li key={link} className="shopping-options-card__buy-now-option">
              <a
                className="shopping-options-card__buy-now-link"
                href={link}
                onFocus={onFocus}
                target="_blank"
                rel="noreferrer noopener"
                {...(emitGtmEvent && { onClick: retailerClickHandler(buyNowAlt) })}
              >
                <Image imageData={buyNowImage} alt={buyNowAlt} />
              </a>
            </li>
          ))}
        </ul>
        {showBazaarvoiceStarRating && bvProductId ? (
          <BazaarRatingSummary productId={bvProductId} />
        ) : null}
      </div>
    </div>
  );
};

export default ShoppingOptionsCard;